import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'

const Partners = () => {

  let [partners, setPartners] = useState([])

  useEffect(() => {
    getPartners()
  }, [])

  let getPartners = async () => {
    let response = await fetch('/api/partnerzy')
    let data = await response.json()

    setPartners(data)
  }

  let style = {
    height: "100%",
    width: "100%"
  }

  return (
    <Container id="partners" className="mt-4">
      <h3 className="text-center mb-3">Nasze marki</h3>
      <Row className="mx-auto justify-content-center">
        {partners.map((partner) => {
          return <Col key={partner.id} className="text-center mt-3" xs={6} sm={6} md={4} lg={2}>
            <a href={partner.link} style={{color:"black",textDecoration:"none"}} className="mx-auto">
              <Image src={'/api' + partner.miniaturka} style={style} alt="nie ma obrazka :(" />
              {/* <h6 className="mt-2">{partner.nazwa}</h6> */}
            </a>
          </Col>
        })}
      </Row>
    </Container>
  )
}

export default Partners
