import React, { useState, useEffect } from 'react' 
import { Container, Form, Col, Row, FloatingLabel } from 'react-bootstrap'
import VehicleItem from './VehicleItem'


const VehiclePage = ({ rents  = false }) => {
  
  let [vehicles, setVehicles] = useState([])
  let [categories, setCategories] = useState([])

  let [query, setQuery] = useState("")
  let [displacementMinimum, setDisplacementMinimum] = useState()
  let [displacementMaximum, setDisplacementMaximum] = useState()
  let [yearMinimum, setYearMinimum] = useState()
  let [yearMaximum, setYearMaximum] = useState()
  let [category, setCategory] = useState('-----')
  let [condition, setCondition] = useState('-----')
  let [sorting, setSorting] = useState('-----')

  useEffect(() => {
    getVehicles()
    getCategories()
  }, [])

  let getVehicles = async () => {
    let response = await fetch('/api/pojazdy')
    let data = await response.json()

    setVehicles(data)
  }

  let getCategories = async () => {
    let response = await fetch('/api/pojazdy/kategorie')
    let data = await response.json()

    setCategories(data)
  }
  
  let search = (vehicles) => {
    return vehicles.filter(
      (vehicle) =>
        (vehicle.marka.toLowerCase().includes(query.toLowerCase()) ||
        vehicle.model.toLowerCase().includes(query.toLowerCase())) &&
        ( category !== '-----' ? categories.find(x => x.id === vehicle.kategoria).nazwa === category : true ) &&
        ( condition !== '-----' ? vehicle.stan === condition : true ) &&
        ( displacementMinimum ? vehicle.pojemnosc >= displacementMinimum : true ) &&
        ( displacementMaximum ? vehicle.pojemnosc <= displacementMaximum : true ) &&
        ( yearMinimum ? yearMinimum <= vehicle.rok_produkcji : true ) &&
        ( yearMaximum ? yearMaximum >= vehicle.rok_produkcji : true ) &&
        ( rents === vehicle.wypozyczalnia )
    )
  }

  let custom_sort = (vehicles) => {
    if(sorting === 'Malejąco') {
      return vehicles.sort((a, b) => b.cena - a.cena)
    } else if(sorting === 'Rosnąco') {
      return vehicles.sort((a, b) => a.cena - b.cena)
    } else {
      return vehicles
    }
  }

  return (
    <Container className="mt-3">
      <Container>
        <Form>
          <Row>
            <Col className="col-12 col-lg-4 mb-2">
              <FloatingLabel label='Nazwa'>
                <Form.Control
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder='Nazwa' />
              </FloatingLabel>
            </Col>
            <Col className="col-12 col-lg-4 mb-2">
              <FloatingLabel label='Kategoria'>
                <Form.Select
                  onChange={(e) => setCategory(e.target.value)}
                  placeholder='Kategoria'>
                    <option>-----</option>
                    {categories.map((category) => {
                      return <option key={category.id}>{category.nazwa}</option>
                    })}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col className="col-12 col-lg-4 mb-2">
              <FloatingLabel label='Stan'>
                <Form.Select
                  onChange={(e) => setCondition(e.target.value)}
                  placeholder='Stan'>
                    <option>-----</option>
                    <option>Nowy</option>
                    <option>Używany</option>
                </Form.Select>
              </FloatingLabel>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="col-6 col-lg-3 mb-2">
              <FloatingLabel label='Pojemność min.'>
                <Form.Control
                  onChange={(e) => setDisplacementMinimum(e.target.value)}
                  placeholder='Pojemność min.' />
              </FloatingLabel>
            </Col>
            <Col className="col-6 col-lg-3 mb-2">
              <FloatingLabel label='Pojemność max.'>
                <Form.Control
                  onChange={(e) => setDisplacementMaximum(e.target.value)}
                  placeholder='Pojemność max.' />
              </FloatingLabel>
            </Col>
            <Col className="col-6 col-lg-3 mb-2">
              <FloatingLabel label='Rocznik min.'>
                <Form.Control
                  onChange={(e) => setYearMinimum(e.target.value)}
                  placeholder='Rocznik min.' />
              </FloatingLabel>
            </Col>
            <Col className="col-6 col-lg-3 mb-2">
              <FloatingLabel label='Rocznik max.'>
                <Form.Control
                  onChange={(e) => setYearMaximum(e.target.value)}
                  placeholder='Rocznik max.' />
              </FloatingLabel>
            </Col>
          </Row>
        </Form>
      </Container>
      <Container className="mt-5">
        <Col className="col-12 col-lg-3">
          <FloatingLabel label='Cena'>
            <Form.Select
              onChange={(e) => setSorting(e.target.value)}
              placeholder='Cena'>
                <option>-----</option>
                <option>Rosnąco</option>
                <option>Malejąco</option>
            </Form.Select>
          </FloatingLabel>
        </Col>
        <Row className="mt-3">
          {custom_sort(search(vehicles)).map((vehicle) => {
            return <VehicleItem key={vehicle.id} vehicle={vehicle} categories={categories} />
          })}
        </Row>
      </Container>
    </Container>
  )
}

export default VehiclePage