import { Row, Container } from 'react-bootstrap'

import OfferCategory from './OfferCategory'

import pojazdy from '../images/panele/pojazdy.jpg'
import odziez from '../images/panele/odziez.jpg'
import akcesoria from '../images/panele/akcesoria.jpg'
import wypozyczalnia from '../images/panele/wypozyczalnia.jpg'

const OfferSegment = () => {
  return (
    <Container id="oferta" className="my-5">
      <Row>
        <Container className="col-12 col-md-6 col-lg-3 mb-2">
          <OfferCategory title="Pojazdy" description="Motocykle, Skutery, Elektryki, ATV, Motorowery i wiele innych" link="/pojazdy" src={pojazdy} />
        </Container>
        <Container className="col-12 col-md-6 col-lg-3 mb-2">
          <OfferCategory title="Odzież" description="Kaski, kurtki, buty, spodnie, rękawice i wiele innych" link="/odziez" src={odziez} />
        </Container>
        <Container className="col-12 col-md-6 col-lg-3 mb-2">
          <OfferCategory title="Akcesoria" description="Kłódki, stojaki, kufry, manetki i wiele innych" link="/akcesoria" src={akcesoria} />
        </Container>
        <Container className="col-12 col-md-6 col-lg-3 mb-2">
          <OfferCategory title="Wypożyczalnia" description="Krótkoterminowa, długoterminowa, bez limitu kilometrów" link="/wypozyczalnia" src={wypozyczalnia} />
        </Container>
      </Row>
    </Container>
  )
}

export default OfferSegment