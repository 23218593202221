import { Card, Button } from 'react-bootstrap'

const OfferCategory = ({ title, description, link, src }) => {
  return (
    <Card className="h-100">
      <Card.Header className="bg-dark text-white">
        <h5 className="my-auto"><b>{title.toUpperCase()}</b></h5>
      </Card.Header>
      <Card.Img variant="top" src={src} />
      <Card.Body className="d-flex flex-column">
        <Card.Text>{description}</Card.Text>
        <Button href={link} variant="outline-dark" className="w-100 mt-auto">Zobacz ofertę</Button>
      </Card.Body>
    </Card>
  )
}

export default OfferCategory