import { Container } from 'react-bootstrap'
import PartsForm from './PartsForm'

const PartsSegment = () => {
  return (
    <div id="parts" className="text-white">
      <Container className="col-12 col-md-8 p-5 my-auto">
        <h2 className="text-center">CZĘŚCI</h2>
        <hr />
        <h5>W celu uzyskania informacji na temat dostępności, ceny czy czasu oczekiwania na części prosimy wypełnić poniższy formularz</h5>
        <br />
        <PartsForm />
      </Container>
    </div>
  )
}

export default PartsSegment