import { Container } from 'react-bootstrap'

const AboutUs = () => {
  return (
    <Container id="about-us" className="mt-4">
      <h3 className="text-center mb-3">O nas</h3>
      <p>Firma CENTRUM MOTOCYKLOWE została założona 1 września 1995 roku przez Grzegorza Baranieckiego. Prowadzona jest tu sprzedaż motocykli nowych, akcesoriów, części zamiennych oraz odzieży motocyklowej większości renomowanych producentów</p>
      <p>CENTRUM MOTOCYKLOWE, jako autoryzowany punkt dealerski oferuje nowe motocykle oraz sprzedaż oryginalnych części marek Yamaha, Junak, Keeway, TGB, Arctic Cat i Harley Davidson. Istnieje również możliwość zakupu motocyklii używanych (komisowych), sprowadzonych z USA oraz pojazdów elektrycznych - Super Soco i E-MIO</p>
      <p>Rozbudowana baza serwisowa, w której obsługiwane są wszystkie marki motocykli, oferuje naprawy gwarancyjne, pogwarancyjne oraz posprzedażowe, jak i serwis starszych modeli. Części oferowane przez firmę są oryginalne i pochodzą bezpośrednio od producentów lub od kluczowych krajowych importerów danej marki. W ofercie fimry znajdują się także zamienniki o wysokiej jakości.</p>
    </Container>
  )
}

export default AboutUs
