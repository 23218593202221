import React, { useRef } from 'react'
import { Container, Row, Form, Button, Col } from 'react-bootstrap'
import emailjs from 'emailjs-com'

const PartsForm = () => {

  const form = useRef();

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_xlto8qt', 'template_dx2xc3w', form.current, '5gCQI1WK0oJDXgKlH')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
    e.target.reset()
  }

  return (
    <Container>
      <Form ref={form} onSubmit={sendEmail}>
        <Row className="mb-3">
          <Form.Group as={Col} className="col-12 col-md-6 mt-2">
            <Form.Control placeholder="Imię" required name="imie" />
          </Form.Group>
          <Form.Group as={Col} className="col-12 col-md-6 mt-2">
            <Form.Control placeholder="Numer kontaktowy" required name="kontakt" />
            <Form.Text className="text-muted px-2">
              Nigdy nie udostępnimy nikomu twojego numeru.
            </Form.Text>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Control as="textarea" placeholder="Wiadomość" required name="wiadomosc" />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Col>
            <Button type="submit" className="w-100" variant="outline-light">Wyślij</Button>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}

export default PartsForm