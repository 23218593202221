import { Image } from 'react-bootstrap'
import photo from '../images/americawestride.jpg'

const AmericaWestRide = () => {
  return (
    <a href="https://americawestride.pl">
      <Image id="americawestride" src={photo} style={{ width: "100%" }} />
    </a>
  )
}

export default AmericaWestRide