import React, { useState, useEffect } from 'react' 
import { Container, Form, Col, Row, FloatingLabel } from 'react-bootstrap'

import WearItem from './WearItem'


const WearPage = () => {
  
  let [clothes, setClothes] = useState([])

  let [type, setType] = useState('-----')
  let [query, setQuery] = useState('')
  let [size, setSize] = useState('-----')
  let [color, setColor] = useState('')
  let [sorting, setSorting] = useState('-----')

  useEffect(() => {
    getClothes()
  }, [])

  let getClothes = async () => {
    let response = await fetch('/api/odziez')
    let data = await response.json()

    setClothes(data)
  }
  
  let search = (clothes) => {
    return clothes.filter(
      (wear) =>
        (wear.marka.toLowerCase().includes(query.toLowerCase()) ||
        wear.model.toLowerCase().includes(query.toLowerCase())) &&
        ( type !== '-----' ? wear.typ === type : true ) &&
        ( size !== '-----' ? wear.rozmiar.toLowerCase().includes(size.toLowerCase()) : true ) &&
        ( color ? wear.kolor.toLowerCase().includes(color.toLowerCase()) : true )
    )
  }

  let custom_sort = (clothes) => {
    if(sorting === 'Malejąco') {
      return clothes.sort((a, b) => b.cena - a.cena)
    } else if(sorting === 'Rosnąco') {
      return clothes.sort((a, b) => a.cena - b.cena)
    } else {
      return clothes
    }
  }

  return (
    <Container className="mt-3">
      <Container>
        <Form>
          <Row>
            <Col className="col-12 col-lg-3 mb-2">
              <FloatingLabel label='Typ'>
                <Form.Select
                  onChange={(e) => setType(e.target.value)}
                  placeholder='Typ'>
                    <option>-----</option>
                    <option>Kask</option>
                    <option>Rękawice</option>
                    <option>Kurtka</option>
                    <option>Spodnie</option>
                    <option>Buty</option>
                    <option>Inne</option>
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col className="col-12 col-lg-3 mb-2">
              <FloatingLabel label='Nazwa'>
                <Form.Control
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder='Nazwa' />
              </FloatingLabel>
            </Col>
            <Col className="col-12 col-lg-3 mb-2">
              <FloatingLabel label='Rozmiar'>
                <Form.Select
                  onChange={(e) => setSize(e.target.value)}
                  placeholder='Rozmiar'>
                    <option>-----</option>
                    <option>XXS</option>
                    <option>XS</option>
                    <option>S</option>
                    <option>M</option>
                    <option>L</option>
                    <option>XXL</option>
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col className="col-12 col-md-6 col-lg-3 mb-2">
              <FloatingLabel label='Kolor'>
                <Form.Control
                  onChange={(e) => setColor(e.target.value)}
                  placeholder='Kolor' />
              </FloatingLabel>
            </Col>
          </Row>
        </Form>
      </Container>
      <Container className="mt-5">
        <Col className="col-12 col-lg-3">
          <FloatingLabel label='Cena'>
            <Form.Select
              onChange={(e) => setSorting(e.target.value)}
              placeholder='Cena'>
                <option>-----</option>
                <option>Rosnąco</option>
                <option>Malejąco</option>
            </Form.Select>
          </FloatingLabel>
        </Col>
        <Row className="mt-3">
          {custom_sort(search(clothes)).map((wear) => {
            return <WearItem key={wear.id} wear={wear} />
          })}
        </Row>
      </Container>
    </Container>
  )
}

export default WearPage
