import React, { useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Toast, ToastContainer } from 'react-bootstrap'

import NavigationBar from './components/NavigationBar';
import HomePage from './components/HomePage';
import VehiclePage from './components/VehiclePage';
import WearPage from './components/WearPage';
import AccessoriesPage from './components/AccessoriesPage';

const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path='/' element={<HomePage />}></Route>
          <Route exact path='/pojazdy' element={<><NavigationBar /><VehiclePage /></>}></Route>
          <Route exact path='/odziez' element={<><NavigationBar /><WearPage /></>}></Route>
          <Route exact path='/akcesoria' element={<><NavigationBar /><AccessoriesPage /></>}></Route>
          <Route exact path='/wypozyczalnia' element={<><NavigationBar /><VehiclePage rents /></>}></Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
