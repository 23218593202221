import { Container } from 'react-bootstrap'

const ServiceSegment = () => {
  return (
    <div id="service" className="text-white">
      <Container className="col-12 col-md-8 p-5 my-auto">
        <h2 className="text-center">SERWIS</h2>
        <hr />
        <h5>Usługi serwisowe wykonuje u nas firma <b>Robert Szatkowski SKUTER MANIAK</b>, wszystkie pytania dotyczące kosztów, czasu oczekiwania oraz usług prosimy kierować pod numer:</h5>
        <br />
        <h5 className="text-center"><a href="tel:500606904" style={{color:"white",textDecoration:"none"}}>☎️ +48 500 606 904 ☎️</a></h5>
      </Container>
    </div>
  )
}

export default ServiceSegment