import { Navbar, Container } from 'react-bootstrap'
import NavLinks from './NavigationBarInner'

const NavigationBar = () => {
  return (
    <Navbar collapseOnSelect bg="light" expand="lg">
      <Container className="d-block">
        <NavLinks />
      </Container>
    </Navbar>
  )
}

export default NavigationBar