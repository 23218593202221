import React, { useState, useEffect } from 'react'
import { Card, Col, Button, Offcanvas, Row, Carousel } from 'react-bootstrap'
import parser from 'html-react-parser'

const WearItem = ({ wear }) => {
  
  let [pictures, setPictures] = useState([])
  let [show, setShow] = useState(false)
  let [isMobile, setMobile] = useState(false)

  let handleShow = () => { setShow(true) }
  let handleHide = () => { setShow(false) }

  useEffect(() => {
    getPictures()
    setMobile(window.innerWidth < 1200)
    window.addEventListener('resize', () => {
      setMobile(window.innerWidth < 1200)
    })
  }, [])

  let getPictures = async () => {
    let response = await fetch('/api/odziez/zdjecia/' + wear.id)
    let data = await response.json()
 
    setPictures(data)
  }

  let style = {
    height: isMobile ? "30vh" : "55vh",
    objectFit: "cover"
  }

  function addSeparatorsNF(nStr, inD, outD, sep) {
    nStr += '';
    var dpos = nStr.indexOf(inD);
    var nStrEnd = '';
    if (dpos != -1) {
      nStrEnd = outD + nStr.substring(dpos + 1, nStr.length);
      nStr = nStr.substring(0, dpos);
    }
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(nStr)) {
      nStr = nStr.replace(rgx, '$1' + sep + '$2');
    }
    return nStr + nStrEnd;
  }
  
  return (
    <>
      <Col className="col-12 col-md-6 col-lg-3 mb-3">
        <Card className="h-100">
          <Card.Img variant="top" src={'/api' + wear.miniaturka} className="icon" />
          <Card.Body className="d-flex flex-column">
            <Card.Title>{wear.marka + ' ' + wear.model}</Card.Title>
            <div>
              <Card.Text className="text-secondary">
                {wear.typ}<br />
                Rozmiar: {wear.rozmiar}<br />
                Kolor: {wear.kolor}<br />
                <hr />
                {addSeparatorsNF(wear.cena, '.', '.', ' ')} PLN
              </Card.Text>
            </div>
            <div className="mt-auto">
              <Button className="outline-dark w-100 mt-3" onClick={handleShow}>Więcej informacji</Button>
            </div>
          </Card.Body>
        </Card>
      </Col>

      <Offcanvas show={show} onHide={handleHide} className={isMobile ? "w-100" : "w-50"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{wear.marka + ' ' + wear.model}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Carousel className="m-0 p-0 mb-3">
            {pictures.map((picture) => {
              return <Carousel.Item key={picture.id}>
                <img className="d-block w-100 vehicle-carousel" style={style} src={'/api' + picture.zdjecie} alt="problem z wyświetleniem zdjęcia" />
              </Carousel.Item>
            })}
          </Carousel>
          <Row className="text-center mb-4">
            <Col><b>Rozmiar</b><br />{wear.rozmiar}</Col>
            <Col><b>Kolor</b><br />{wear.kolor}</Col>
            <Col><b>Cena</b><br />{addSeparatorsNF(wear.cena, '.', '.', ' ')} zł</Col>
          </Row>
          <p className="m-4">{parser(wear.opis)}</p>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default WearItem