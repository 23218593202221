import { Card, Col, Row } from 'react-bootstrap'

const ContactElement = ({ title, phone_numbers }) => {
  return (
    <Col className="mb-2 col-12 col-lg-4">
      <Card className="h-100">
        <Card.Body>
          <Card.Title><h6 className="text-center">{title}</h6></Card.Title>
          <Card.Text>
            <hr />
            <Row>
              {phone_numbers.map((number) => {
                return <Col className="text-center col-12 col-md-6 mx-auto">
                    <a href={ "tel:" + number.split(' ').join('') }
                      style={{color:"black",textDecoration:"none"}}
                      adjustFontSizeToFit={true}
                      numberOfLines={1}>☎️ +48 {number} ☎️</a>
                  </Col>
              })}
            </Row>
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  )
}

export default ContactElement