import React, { useState, useEffect } from 'react' 
import { Container, Form, Col, Row, FloatingLabel } from 'react-bootstrap'

import AccessoryItem from './AccessoryItem'

const AccessoriesPage = () => {

  let [accessories, setAccessories] = useState([])

  let [query, setQuery] = useState('')
  let [sorting, setSorting] = useState('-----')

  useEffect(() => {
    getAccessories()
  }, [])

  let getAccessories = async () => {
    let response = await fetch('/api/akcesoria')
    let data = await response.json()

    setAccessories(data)
  }
  
  let search = (accessories) => {
    return accessories.filter(
      (accessory) => 
        accessory.nazwa.toLowerCase().includes(query.toLowerCase())
    )
  }

  let custom_sort = (accessories) => {
    if(sorting === 'Malejąco') {
      return accessories.sort((a, b) => b.cena - a.cena)
    } else if(sorting === 'Rosnąco') {
      return accessories.sort((a, b) => a.cena - b.cena)
    } else {
      return accessories
    }
  }

  return (
    <Container className="mt-3">
      <Container>
        <Form>
          <Row>
            <Col className="col-12">
              <FloatingLabel label='Nazwa'>
                <Form.Control
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder='Nazwa' />
              </FloatingLabel>
            </Col>
          </Row>
        </Form>
      </Container>
      <Container className="mt-5">
        <Col className="col-12 col-lg-3">
          <FloatingLabel label='Cena'>
            <Form.Select
              onChange={(e) => setSorting(e.target.value)}
              placeholder='Cena'>
                <option>-----</option>
                <option>Rosnąco</option>
                <option>Malejąco</option>
            </Form.Select>
          </FloatingLabel>
        </Col>
        <Row className="mt-3">
          {custom_sort(search(accessories)).map((accessory) => {
            return <AccessoryItem key={accessory.id} accessory={accessory} />
          })}
        </Row>
      </Container>
    </Container>
  )
}

export default AccessoriesPage
