import React, { useState, useEffect } from 'react'
import { Card, Col, Button, Offcanvas, Row, Carousel, Container } from 'react-bootstrap'
import parser from 'html-react-parser'

const VehicleItem = ({ vehicle, categories }) => {

  let category = categories.find(x => x.id === vehicle.kategoria)
  let [pictures, setPictures] = useState([])
  let [show, setShow] = useState(false)
  let [isMobile, setMobile] = useState(false)

  let handleShow = () => { setShow(true) }
  let handleHide = () => { setShow(false) }

  useEffect(() => {
    getPictures()
    setMobile(window.innerWidth < 1200)
    window.addEventListener('resize', () => {
      setMobile(window.innerWidth < 1200)
    })
  }, [])

  let getPictures = async () => {
    let response = await fetch('/api/pojazdy/zdjecia/' + vehicle.id)
    let data = await response.json()
 
    setPictures(data)
  }

  let style = {
    height: isMobile ? "30vh" : "55vh",
    objectFit: "cover"
  }

  function addSeparatorsNF(nStr, inD, outD, sep) {
    nStr += '';
    var dpos = nStr.indexOf(inD);
    var nStrEnd = '';
    if (dpos != -1) {
      nStrEnd = outD + nStr.substring(dpos + 1, nStr.length);
      nStr = nStr.substring(0, dpos);
    }
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(nStr)) {
      nStr = nStr.replace(rgx, '$1' + sep + '$2');
    }
    return nStr + nStrEnd;
  }

  return (
    <>
      <Col className="col-12 col-md-6 col-lg-3 mb-3">
        <Card className="h-100">
          <Card.Img variant="top" src={'/api' + vehicle.miniaturka} className="icon" />
          <Card.Body className="d-flex flex-column">
            <Card.Title>{vehicle.marka + ' ' + vehicle.model}</Card.Title>
            <div>
              <Card.Text className="text-secondary">
                {category.nazwa}, {vehicle.stan.toLowerCase()}<br />
                Pojemność: {vehicle.pojemnosc} cm<sup>3</sup><br />
                Rok produkcji: {vehicle.rok_produkcji}<br />
                Przebieg: {addSeparatorsNF(vehicle.przebieg, '.', '.', ' ')} {vehicle.jednostka === "km" ? "km" : "mil"}<br />
                Moc: {vehicle.moc} KM / {vehicle.kw != 0 ? vehicle.kw : (vehicle.moc*0.74).toFixed(2)} kW<br />
                Prawo jazdy: {vehicle.uprawnienia}<br />
                <hr />
                {vehicle.wypozyczalnia ? addSeparatorsNF(vehicle.cena_za_dobe, '.', '.', ' ') + " PLN/DOBA" : addSeparatorsNF(vehicle.cena, '.', '.', ' ') + " PLN"}
              </Card.Text>
            </div>
            <div className="mt-auto">
              <Button className="outline-dark w-100 mt-3" onClick={handleShow}>Więcej informacji</Button>
            </div>
          </Card.Body>
        </Card>
      </Col>

      <Offcanvas show={show} onHide={handleHide} className={isMobile ? "w-100" : "w-50"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{vehicle.marka + ' ' + vehicle.model} &emsp; - &emsp; {category.nazwa}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Carousel className="m-0 p-0 mb-3">
            {pictures.map((picture) => {
              return <Carousel.Item key={picture.id}>
                <img className="d-block w-100 vehicle-carousel" style={style} src={'/api' + picture.zdjecie} alt="problem z wyświetleniem zdjęcia" />
              </Carousel.Item>
            })}
          </Carousel>
          <Row className="text-center">
            <Col><b>Stan</b><br />{vehicle.stan}</Col>
            <Col><b>Pojemność silnika</b><br />{vehicle.pojemnosc} cm<sup>3</sup></Col>
            <Col><b>Rok produkcji</b><br />{vehicle.rok_produkcji}</Col>
            <Col><b>Przebieg</b><br />{addSeparatorsNF(vehicle.przebieg, '.', '.', ' ')} {vehicle.jednostka === "km" ? "km" : "mil"}</Col>
          </Row>
          <Row className="text-center mt-2 mb-4">
            <Col><b>Moc</b><br />{vehicle.moc} KM / {vehicle.kw != 0 ? vehicle.kw : (vehicle.moc*0.74).toFixed(2)} kW</Col>
            <Col><b>Prawo jazdy</b><br />{vehicle.uprawnienia}</Col>
            <Col><b>Masa</b><br />{addSeparatorsNF(vehicle.masa, '.', '.', ' ')} kg</Col>
            <Col><b>Cena</b><br />{vehicle.wypozyczalnia ? addSeparatorsNF(vehicle.cena_za_dobe, '.', '.', ' ') + " zł/doba" : addSeparatorsNF(vehicle.cena, '.', '.', ' ') + " zł"}</Col>
          </Row>
          <p className="m-4">{parser(vehicle.opis)}</p>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default VehicleItem